import Phaser from "phaser";
// @ts-ignore
import WebFont from "webfontloader";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";

export default class Boot extends Phaser.Scene {

    constructor() {
        super({
            key: "boot"
        });
    }

    preload() {
        this.load.on("complete", () => {
            WebFont.load({
                custom: {
                    families: [Constants.FONT_BOLD, Constants.FONT_REGULAR]
                },
                active: () => this.booted(true),
                inactive: () => this.booted(false)
            });
        });

        //Load images
        this.load.image("background", Scaling.ImagePath("background", "png"));        
        this.load.image("logo", Scaling.ImagePath("logo", "png"));
        this.load.image("loader-empty", Scaling.ImagePath("loader_empty", "png"));
        this.load.image("loader-filled", Scaling.ImagePath("loader_fill", "png"));
    }

    booted(fontsLoaded: boolean) {
        if (!fontsLoaded) {
            console.error("fonts failed to load...");
        }

        // Scene: launch parallel background scene //
        // NOTE: When not using background, you can place the booted event here
        this.scene.launch("background");
        this.scene.stop();
    }
}
