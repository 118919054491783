import Phaser from "phaser";
import GameHelper from "../util/game-helper";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";
import Difficulty from "../configs/difficulty";

export default class InfiniteBackground extends Phaser.GameObjects.Container {
    speed: number;
    backgroundImage: Phaser.GameObjects.TileSprite;
    waveLeft: Phaser.GameObjects.TileSprite;
    waveRight: Phaser.GameObjects.TileSprite;

    constructor(scene: Phaser.Scene) {
        super(scene, 0, 0);
        this.setDepth(Constants.DEPTH_BACKGROUND);
        this.speed = Scaling.SPEED_ENEMY * Difficulty.SPEED_MULTIPLIER;

        //Add background image
        this.backgroundImage = new Phaser.GameObjects.TileSprite(scene, 0, 0, this.scene.cameras.main.width, this.scene.cameras.main.height, "background-game").setOrigin(0,0);
        this.add(this.backgroundImage);

        //Add left wave
        this.waveLeft = new Phaser.GameObjects.TileSprite(scene, 0, 0, 0, 0, "background-waves-left");
        const waveWidthLeft = GameHelper.calculateComponentWidth(this.waveLeft.width, this.waveLeft.height, this.scene.sys.canvas.height);
        this.waveLeft.setDisplaySize(waveWidthLeft, this.scene.sys.canvas.height);
        this.waveLeft.setPosition(this.waveLeft.displayWidth / 2, this.waveLeft.displayHeight / 2);
        this.add(this.waveLeft);

        //Add right wave
        this.waveRight = new Phaser.GameObjects.TileSprite(scene, 0, 0, 0, 0, "background-waves-right");
        const waveWidthRight = GameHelper.calculateComponentWidth(this.waveRight.width, this.waveRight.height, this.scene.sys.canvas.height);
        this.waveRight.setDisplaySize(waveWidthRight, this.scene.sys.canvas.height);
        this.waveRight.setPosition(this.scene.sys.canvas.width - this.waveRight.displayWidth / 2, this.waveRight.displayHeight / 2);
        this.add(this.waveRight);

        //Add to scene
        scene.add.existing(this);

        this.scene.game.events.on("speedUp", (speed: number) => {
            this.speed = Scaling.SPEED_ENEMY * Difficulty.SPEED_MULTIPLIER + speed
        })
    }

    update() {
        const speedMultiply = 1.2
        this.waveLeft.tilePositionY -= this.speed * speedMultiply;
        this.waveRight.tilePositionY -= this.speed * speedMultiply;
        this.backgroundImage.tilePositionY -= (this.speed * speedMultiply) / 1.2;
    }

}
