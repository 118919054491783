import Phaser from "phaser";
import Constants from "../../configs/constants";
import Difficulty from "../../configs/difficulty";
import Scaling from "../../configs/scaling";
import BaseEnemy from "./base-enemy";

export default class MediumEnemy extends BaseEnemy {

    constructor(scene: Phaser.Scene) {
        super(scene, 'enemy-medium-1-1');

        //Set data
        this.enemyType = "medium";
        this.maxHealth = this.health = Constants.HEALTH_ENEMY_MEDIUM;
        this.points = Constants.SCORE_ENEMY_MEDIUM;
        this.speed = Scaling.SPEED_ENEMY * Difficulty.SPEED_MULTIPLIER + this.speedAccelerate;

        
        // Textures
        this.SetStyleAmount()
        this.GetAllStateNames();
        this.setFrame(this.allStates[0])
    }
}
