import Phaser from "phaser";
import Constants from "@/game/shared/utils/Constants";
import Scaling from "@/game/shared/utils/Scaling";

export interface IHUDBoxSettings {
  iconTexture: string,
  value: string,
  minWidth: number,
  origin?: Phaser.Math.Vector2,
  color?: IHUDBoxColor,
}

interface IHUDBoxColor {
  iconBox?: number,
  textBox?: number,
  text?: string
}

export default class HUDBox extends Phaser.GameObjects.Container {

  private readonly textBox!: Phaser.GameObjects.Graphics;
  private readonly text!: Phaser.GameObjects.Text;
  private readonly settings: IHUDBoxSettings;
  private readonly originalX: number;
  private readonly originalY: number;
  private readonly baseSize: number = Scaling.getPixelbyDPR(30);
  private readonly basePadding: number = Scaling.getPixelbyDPR(10);
  private readonly baseRadius: number = Scaling.getPixelbyDPR(5);

  private cachedTextBoxWidth: number = 0;

  constructor(scene: Phaser.Scene, x: number, y: number, settings: IHUDBoxSettings) {
    super(scene, x, y);
    this.scene.add.existing(this);

    //Store values
    this.originalX = x;
    this.originalY = y;
    this.settings = settings;

    //Get colors
    const iconBoxColor = Number.isInteger(this.settings.color?.iconBox) ? this.settings.color?.iconBox : 0xFFFFFF;
    const textBoxColor = Number.isInteger(this.settings.color?.textBox) ? this.settings.color?.textBox : 0x666666;

    //Create icon box
    const iconBox = new Phaser.GameObjects.Graphics(this.scene, { fillStyle: { color: iconBoxColor} });
    iconBox.fillRoundedRect(0, 0, this.baseSize, this.baseSize, { tl: this.baseRadius, bl: this.baseRadius, tr: 0, br: 0 });

    //Create icon
    const iconX = iconBox.x + (this.baseSize / 2);
    const iconY = iconBox.y + (this.baseSize / 2);
    const iconImage = new Phaser.GameObjects.Image(this.scene, iconX, iconY, this.settings.iconTexture);
    iconImage.setDisplaySize(this.baseSize / 2, this.baseSize / 2);

    //Create text (needed for box calculations)
    this.text = new Phaser.GameObjects.Text(this.scene, 0, 0, "", this.getTextProperties());
    this.text.setOrigin(0.5);

    this.textBox = new Phaser.GameObjects.Graphics(this.scene, { x: iconBox.x + this.baseSize, fillStyle: { color: textBoxColor } });
    this.updateText(this.settings.value);

    //Add items to container
    this.add([iconBox, iconImage, this.textBox, this.text]);
    this.updatePosition(this.cachedTextBoxWidth + this.baseSize);

    //Events
    this.on("resize", (newWidth: number) => {
      this.updatePosition(newWidth);
    });
  }

  updatePosition(width: number) {
    this.setX(this.originalX - (width * (this.settings.origin?.x || 0)));
  }

  updateText(newText: string) {
    const oldLength = this.text.text.length;
    this.text.text = newText;

    //Check if we need to redraw
    let textBoxWidth = this.cachedTextBoxWidth;

    if (oldLength !== newText.length) {
      //Clear box for redraw
      this.textBox.clear();

      //Calculate new textbox width
      textBoxWidth = this.text.width + (this.basePadding * 2);
      if (textBoxWidth < this.settings.minWidth) {
        textBoxWidth = this.settings.minWidth;
      }

      //Draw textbox
      this.textBox.fillRoundedRect(0, 0, textBoxWidth, this.baseSize, { tl: 0, bl: 0, tr: this.baseRadius, br: this.baseRadius });
    }

    new Phaser.Math.Vector2(0, 0)

    //Position text
    const textX = (this.textBox.x + (textBoxWidth - this.text.width) / 2) + (this.text.width / 2);
    const textY = (this.textBox.y + (this.baseSize - this.text.height) / 2) + (this.text.height / 2);
    this.text.setPosition(textX, textY);

    //Update cache values
    if (textBoxWidth !== this.cachedTextBoxWidth) {
      this.cachedTextBoxWidth = textBoxWidth;

      //Also emit event
      if (this.listenerCount("resize") !== 0) {
        this.emit("resize", this.baseSize + this.cachedTextBoxWidth);
      }
    }
  }

  animateText() {
    this.scene.tweens.add({
      targets: this.text,
      scale: { from: 1, to: 1.2 },
      duration: 80,
      ease: 'Power.1',
      yoyo: true
    })
  }

  getTextProperties(): Phaser.Types.GameObjects.Text.TextStyle {
    return {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      color: this.settings.color?.text || '#FFFFFF'
    }
  }
}