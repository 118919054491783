import Setup from "@/helpers/Setup";

export default class Scaling {

    //Base values
    static GAME_BASE_WIDTH = 375;
    static GAME_BASE_HEIGHT = 667;

    //Calculated sizes/ratios
    static GAME_WIDTH: number = Scaling.GAME_BASE_WIDTH;
    static GAME_HEIGHT: number = Scaling.GAME_BASE_HEIGHT;
    static GAME_DIFF_RATIO = 1;

    //DPR
    static DPR: number = Scaling.getDevicePixelRatio();

    //Helpers
    static updateResolutionRatio(parentWidth: number, parentHeight: number): void {
        const diff = Math.abs((parentWidth / parentHeight) - (this.GAME_BASE_WIDTH / this.GAME_BASE_HEIGHT));

        //Update width/height if ratio offset too large
        if (diff > 0.001) {
            this.GAME_WIDTH = parentWidth;
            this.GAME_HEIGHT = parentHeight;
        }

        //Update diff ratio
        this.GAME_DIFF_RATIO = parentHeight / this.GAME_BASE_HEIGHT;
    }

    static getDevicePixelRatio(): number {
        const params = new URLSearchParams(window.location.search);
        const forceDpr = params.get("dpr");
        if (forceDpr) {
            return Math.floor(parseInt(forceDpr))
        }
        return Math.floor(Math.min(window.devicePixelRatio, 3));
    }

    static getPixelbyDPR(px: number) {
        return px * Scaling.DPR;
    }

    static ImagePath(filename: string, extension: string, size = 0) {
        if (!size) {
            size = this.DPR;
        }

        const filePath = `${filename}@${size}x.${extension}`;
        return Setup.getImage(filePath, "game");
    }
}
