import Phaser from "phaser";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";
import GameHelper from "../util/game-helper";

export default class Explosion extends Phaser.GameObjects.Sprite {

    constructor(scene: Phaser.Scene, x: number, y: number, small = false) {
        super(scene, x, y, "explosion");

        //Set size & depth
        let newWidth: number, newHeight = 0;
        if (!small) {
            newWidth = Scaling.EXPLOSION_WIDTH;
            newHeight = GameHelper.calculateComponentHeight(this.width, this.height, Scaling.EXPLOSION_WIDTH);

        } else {
            newWidth = Scaling.EXPLOSION_SMALL_WIDTH;
            newHeight = GameHelper.calculateComponentHeight(this.width, this.height, Scaling.EXPLOSION_SMALL_WIDTH);
        }
        this.setDisplaySize(newWidth, newHeight);
        this.setDepth(Constants.DEPTH_EXPLOSION);

        //Add
        scene.add.existing(this);

        //Play animation
        this.on("animationcomplete", () => {
            this.emit("completed");
            this.destroy();
        });
        this.anims.play("run-explosion");
    }
}
