import Phaser from "phaser";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";
import BaseEnemy from "./enemies/base-enemy";
import Player from "./player";

//Tester: https://koreezgames.github.io/phaser3-particle-editor/

export default class ParticleManager extends Phaser.GameObjects.Particles.ParticleEmitterManager {

    constructor(scene: Phaser.Scene) {
        super(scene, "particles");
        this.scene.add.existing(this);
        this.setDepth(Constants.DEPTH_PARTICLES);
    }

    addPlayerBoosterEmitter(player: Player) {
        const emitter = new Phaser.GameObjects.Particles.ParticleEmitter(this, {
            frequency: 1,
            maxParticles: 500,
            timeScale: 1,
            gravityY: 200,
            maxVelocityY: 300,
            lifespan: 1000,
            quantity: 5,
            follow: player,
            followOffset: {
                x: -(Scaling.BOOSTER_PLAYER_WIDTH / 2),
                y: (player.height / 2)
            },
            tint: [
                0xff0000, 0xff2626, 0xff7f7f
            ],
            alpha: {
                start: 1,
                end: 0.5
            },
            scale: {
                start: 0.8,
                end: 0
            },
            emitZone: {
                type: "random",
                source: new Phaser.Geom.Triangle(0, 0, Scaling.BOOSTER_PLAYER_WIDTH, 0, Scaling.BOOSTER_PLAYER_WIDTH / 2, Scaling.BOOSTER_PLAYER_HEIGHT) as Phaser.Types.GameObjects.Particles.RandomZoneSource
            },
            frame: 1
        });
        this.addEmitter(emitter);
        return emitter;
    }

    addEnemyBoosterEmitter(enemy: BaseEnemy) {
        const emitter = new Phaser.GameObjects.Particles.ParticleEmitter(this, {
            frequency: 1,
            maxParticles: 500,
            timeScale: 1,
            gravityY: -50,
            maxVelocityY: 50,
            lifespan: 500,
            quantity: 4,
            follow: enemy,
            followOffset: {
                x: -(Scaling.BOOSTER_ENEMY_WIDTH / 2),
                y: -enemy.displayHeight + Scaling.BOOSTER_ENEMY_HEIGHT / 2 + Scaling.BOOSTER_CORRECTION
            },
            tint: [
                0x00ff00, 0x00fe00, 0x7cff7c
            ],
            alpha: {
                start: 1,
                end: 0.5
            },
            scale: {
                start: 0.8,
                end: 0
            },
            emitZone: {
                type: 'random',
                source: new Phaser.Geom.Triangle(0, Scaling.BOOSTER_ENEMY_HEIGHT, Scaling.BOOSTER_ENEMY_WIDTH, Scaling.BOOSTER_ENEMY_HEIGHT, Scaling.BOOSTER_ENEMY_WIDTH / 2, 0) as any // casting ass zone give missing getrandompoint
            },
            frame: 2
        });

        this.addEmitter(emitter);
        return emitter;
    }

    removeEmitter(emitter: Phaser.GameObjects.Particles.ParticleEmitter) {
        this.emitters.remove(emitter);
        return emitter
    }
}
