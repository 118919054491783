import Constants from "../../configs/constants";
import Difficulty from "../../configs/difficulty";
import BaseEnemy from "../enemies/base-enemy";
import BaseBullet from "./base-bullet";

export default class EnemyBullet extends BaseBullet {
    enemy: BaseEnemy

    // added constants cause it was the same thing basically also it seems to fix the bullet collision bug (bullet could hit the original shooter)
    constructor(scene: Phaser.Scene, enemy: BaseEnemy, speed: number) {
        super(scene, Constants.CATEGORY_ENEMY, "bullet-enemy");

        //Set data
        this.direction = 1;
        this.speed = speed * Difficulty.SPEED_MULTIPLIER;

        //Set starting position
        this.setPosition(enemy.x, enemy.y + (enemy.displayHeight / 2) - (this.displayHeight / 2));
        this.setRotation(180 * Phaser.Math.DEG_TO_RAD);
        this.enemy = enemy

        //Play sound
        this.scene.sound.add("laser-mini").play();
    }

    alive() {
        super.alive()
        this.setPosition(this.enemy.x, this.enemy.y + (this.enemy.displayHeight / 2) - (this.displayHeight / 2));
        this.setRotation(180 * Phaser.Math.DEG_TO_RAD);
    }
}
