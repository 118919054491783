import Phaser from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import GameHelper from "../../util/game-helper";
import Explosion from "../explosion";

export default class BaseBullet extends Phaser.GameObjects.Sprite {
    category: string;
    isOutOfBounds: boolean;
    direction: number;
    speed: number;

    constructor(scene: Phaser.Scene, source: string, texture: string | Phaser.Textures.Texture) {
        super(scene, 0, 0, texture);
        this.scene.add.existing(this);
        this.setDepth(Constants.DEPTH_BEAM);

        //Set data
        this.category = source;
        this.isOutOfBounds = false;
        this.direction = 1;
        this.speed = 1;

        //Set physics
        this.scene.physics.world.enable(this);
        const arcadeBody = this.body as Phaser.Physics.Arcade.Body;
        arcadeBody.setSize(this.width / 2, this.height * (3 / 4)); //For the hit collision

        //Set size
        // this.setDisplaySize(Scaling.BEAM_WIDTH, GameHelper.calculateComponentHeight(this.width, this.height, Scaling.BEAM_WIDTH));
    }

    update() {
        if (!this.active)
            return;

        //Move down
        this.y += (this.speed * this.direction);

        //Check bounds
        this.isOutOfBounds = this.y > (this.scene.sys.canvas.height + this.displayHeight / 2) || this.y < -(this.displayHeight / 2);

        if(this.isOutOfBounds) {
            this.death(true)
        }

    }

    alive() {
        this.active = true
        this.setActive(true)
        this.setVisible(true)
    }

    //Life & death
    death(silent: boolean) {
        if (!this.active)
            return;

        //Cleanup
        this.active = false;
        this.setActive(false)
        this.setVisible(false)

        //Explosion on death
        if (!silent) {
            new Explosion(this.scene, this.x, this.y, true);
        }
    }
}
