import Phaser from "phaser";
import Constants from "../../configs/constants";
import Difficulty from "../../configs/difficulty";
import Scaling from "../../configs/scaling";
import GameHelper from "../../util/game-helper";
import BaseEnemy from "./base-enemy";

export default class LightEnemy extends BaseEnemy {

    constructor(scene: Phaser.Scene) {
        super(scene, 'enemy-light-1-1');
        
        //Set data
        this.enemyType = "light";
        this.maxHealth = this.health = Constants.HEALTH_ENEMY_LIGHT;
        this.points = Constants.SCORE_ENEMY_LIGHT;
        this.speed = Scaling.SPEED_ENEMY * Difficulty.SPEED_MULTIPLIER + this.speedAccelerate;

        // Textures
        this.SetStyleAmount()
        this.GetAllStateNames();
        this.setFrame(this.allStates[0])
    }
}
