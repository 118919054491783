export default class GameHelper {

    static calculateComponentHeight(originalWidth: number, originalHeight: number, targetWidth: number) {
        const aspectRatio = originalHeight / originalWidth;
        return targetWidth * aspectRatio;
    }

    static calculateComponentWidth(originalWidth: number, originalHeight: number, targetHeight: number) {
        const aspectRatio = originalWidth / originalHeight;
        return targetHeight * aspectRatio;
    }
}
