import Setup from "@/helpers/Setup";

export default class Scaling {

    //Base values
    static GAME_BASE_WIDTH = 375;
    static GAME_BASE_HEIGHT = 667;
    static GAME_BASE_DIFF_HEIGHT = (window.innerHeight / Scaling.GAME_BASE_HEIGHT) > 1 ? (window.innerHeight / Scaling.GAME_BASE_HEIGHT) : 1;

    //Calculated sizes/ratios
    static GAME_WIDTH = Scaling.GAME_BASE_WIDTH;
    static GAME_HEIGHT = Scaling.GAME_BASE_HEIGHT;
    static GAME_DIFF_RATIO = 1;

    //DPR
    static DPR = Scaling.getDevicePixelRatio();
    static getPixelbyDPR(px: number) {
        return px * this.DPR;
    }

    //Helpers
    static updateResolutionRatio(parentWidth: number, parentHeight: number) {
        const diff = Math.abs((parentWidth / parentHeight) - (this.GAME_BASE_WIDTH / this.GAME_BASE_HEIGHT));

        //Update width/height if ratio offset too large
        if (diff > 0.001) {
            this.GAME_WIDTH = parentWidth;
            this.GAME_HEIGHT = parentHeight;
        }

        //Update diff ratio
        const diffRatio = parentHeight / this.GAME_BASE_HEIGHT;
        if(diffRatio > 1) {
            this.GAME_DIFF_RATIO = diffRatio;
        }
    }

    static getDevicePixelRatio() {
        const params = new URLSearchParams(window.location.search);
        const forceDpr = params.get("dpr");
        if (forceDpr) {
            return Math.floor(parseInt(forceDpr))
        }
        return Math.floor(Math.min(window.devicePixelRatio, 3));
    }

    static ImagePath(filename: string, extension: string, size = 0) {
        if (!size) {
            size = this.DPR;
        }

        const filePath = `${filename}@${size}x.${extension}`;
        const file = Setup.getImage(filePath, "game");
        
        return file;
    }

    //Graphics
    static BEAM_WIDTH = 15 * Scaling.DPR;

    //Components
    static PLAYER_WIDTH = 50 * Scaling.DPR;
    static ENEMY_HEAVY_WIDTH = 55 * Scaling.DPR;
    static ENEMY_LIGHT_WIDTH = 55 * Scaling.DPR;
    static ENEMY_KAMIKAZE_WIDTH = 55 * Scaling.DPR;
    static ENEMY_ROCK_MEDIUM_WIDTH = 45 * Scaling.DPR;
    static ENEMY_ROCK_SMALL_WIDTH = 25 * Scaling.DPR;
    static EXPLOSION_WIDTH = 50 * Scaling.DPR;
    static EXPLOSION_SMALL_WIDTH = 30 * Scaling.DPR;

    //Loading
    static LOAD_LOGO_WIDTH = 300 * Scaling.DPR;

    //HUD
    static HUD_HEALTH_WIDTH = 16 * Scaling.DPR;
    static HUD_HEALTH_MARGIN = 6 * Scaling.DPR;
    static HUD_TEXT_SIZE = 18 * Scaling.DPR;
    static HUD_TITLE_SIZE = 12 * Scaling.DPR;
    static HUD_DEFAULT_MARGIN = 16 * Scaling.DPR;
    static HUD_SCORE_MARGIN = 6 * Scaling.DPR;
    static HUD_SCORE_ICON_WIDTH = 24 * Scaling.DPR;
    static HUD_SCORE_TEXT_SPACE_WIDTH = 68 * Scaling.DPR;
    static HUD_SCORE_TEXT_CORRECTION = 1 * Scaling.DPR;

    //Particles
    static BOOSTER_PLAYER_WIDTH = 18 * Scaling.DPR;
    static BOOSTER_PLAYER_HEIGHT = 15 * Scaling.DPR;
    static BOOSTER_ENEMY_WIDTH = 12 * Scaling.DPR;
    static BOOSTER_ENEMY_HEIGHT = 10 * Scaling.DPR;
    static BOOSTER_CORRECTION = 1.5 * Scaling.DPR;

    //Speeds
    static SPEED_ENEMY = 1.5 * Scaling.DPR
    // static SPEED_ENEMY_HEAVY = 1.5 * Scaling.DPR;
    // static SPEED_ENEMY_LIGHT = 2 * Scaling.DPR;
    static SPEED_ENEMY_KAMIKAZE = 1.5 * Scaling.DPR;
    // static SPEED_ENEMY_ROCK_SMALL = 2 * Scaling.DPR;
    // static SPEED_ENEMY_ROCK_MEDIUM = 1 * Scaling.DPR;
    static SPEED_POWER_UP = 2 * Scaling.DPR;

    //Shooting
    static PLAYER_BEAM_SPEED = 5 * Scaling.DPR;
    static ENEMY_LIGHT_BEAM_SPEED = 3 * Scaling.DPR;
    static ENEMY_HEAVY_BEAM_SPEED = 2 * Scaling.DPR;

    //Power-ups
    static POWERUP_WIDTH = 44 * Scaling.DPR;
    static POWERUP_CIRCLE_WIDTH = 60 * Scaling.DPR;
}
