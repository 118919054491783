export default class Constants {

    //Fonts
    static FONT_BOLD = "font_bold";
    static FONT_REGULAR = "font_regular";

    //Categories
    static CATEGORY_ENEMY = "enemy";
    static CATEGORY_PLAYER = "player";

    //Healths
    static HEALTH_PLAYER = 3;
    static HEALTH_ENEMY_LIGHT = 2;
    static HEALTH_ENEMY_MEDIUM = 3;
    static HEALTH_ENEMY_HEAVY = 6;
    static HEALTH_ENEMY_KAMIKAZE = 2;

    //Scores
    static SCORE_ALIVE_BONUS = 20;
    static SCORE_ALIVE_INTERVAL = 60 * 1000;
    static SCORE_ENEMY_LIGHT = 1;
    static SCORE_ENEMY_MEDIUM = 2;
    static SCORE_ENEMY_HEAVY = 3;
    static SCORE_ENEMY_KAMIKAZE = 1;

    //Shooting - enemy
    static ENEMY_FIRE_RATE_RANDOMNESS = 250;
    static ENEMY_LIGHT_FIRE_RATE = 1500;
    static ENEMY_HEAVY_FIRE_RATE = 3000;

    //Shooting - player
    static PLAYER_BASE_FIRE_RATE = 750;
    static PLAYER_POWERED_FIRE_RATE = 250;

    //Depths
    static DEPTH_BACKGROUND = -1;
    static DEPTH_BEAM = 1;
    static DEPTH_ENEMY = 2;
    static DEPTH_PLAYER = 3;
    static DEPTH_PARTICLES = 4;
    static DEPTH_EXPLOSION = 5;
    static DEPTH_HUD = 20;

    //Powerups
    static POWERUP_COOLDOWN = 10000
    static POWERUP_LIFE = "life";
    static POWERUP_SHIELD = "shield";
    static POWERUP_SHIELD_DURATION = 10000;
    static POWERUP_FIRE_RATE = "fireRate";
    static POWERUP_FIRE_RATE_DURATION = 10000;
    static POWERUP_CLEARROOM = "clearRoom"
    static POWERUP_CLEARROOM_FLASH_TIME = 1000

    //Misc.
    static ENEMY_SPAWN_INTERVAL = 3000;
}
