import Constants from "../../configs/constants"
import Scaling from "../../configs/scaling"
import BaseEnemy from "../enemies/base-enemy"
import Player from "../player"
import BaseBullet from "../weapons/base-bullet"
import EnemyBullet from "../weapons/enemy-bullet"
import PlayerBullet from "../weapons/player-bullet"

export default class BulletController extends Phaser.GameObjects.Group {
    scene: Phaser.Scene
    player: Player

    constructor(scene: Phaser.Scene) {
        super(scene)
        this.scene = scene
        this.player = this.scene.children.getByName("player") as Player

        for (let i = 0; i < 50; i++) {
            const element = new PlayerBullet(this.scene, this.player)
            element.death(true);
            this.add
        }
    }

    spawn(category: string, source: Player | BaseEnemy) {
        let bullet!: BaseBullet

        // get first dead bullet 
        const bulletArray = this.getMatching('category', category)
        for (let i = 0; i < bulletArray.length; i++) {
            const element = bulletArray[i];
            
            if(!element.active) {
                bullet = element
                break;
            }
        }


        if (bullet !== null && bullet !== undefined) {
            bullet.alive()
            return bullet
        }

        // create new bullet
        switch (category) {
            case Constants.CATEGORY_ENEMY:
                bullet = new EnemyBullet(this.scene, source as BaseEnemy, Scaling.ENEMY_LIGHT_BEAM_SPEED)
                break
            case Constants.CATEGORY_PLAYER:
                bullet = new PlayerBullet(this.scene, this.player, true)
                break
        }
        this.add(bullet)
        return bullet

    }
}