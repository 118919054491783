import Setup from "@/helpers/Setup";
import Phaser, { GameObjects } from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";

export default abstract class UI_Box extends Phaser.GameObjects.Container {
    uiBackground: Phaser.GameObjects.Image;

    constructor(scene: Phaser.Scene, x: number, y: number, backgroundKey: string, xOrigin: number,) {
        super(scene, x, y);

        // TEXT SETUP
        this.uiBackground = this.scene.add.image(0, y, backgroundKey).setOrigin(xOrigin, 0.5);

        this.add([this.uiBackground]);
    }

    abstract updateValue(value: number): void;
}